/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { useStaticQuery, graphql } from "gatsby"
import { jsx, useColorMode } from "theme-ui"

import BookHoelderlinLiebt from "../../../content/hoelderlin-liebt/book"
import Box from "../../../components/box"
import Breadcrumb from "../../../components/breadcrumb"
import Bullet from "../../../components/bullet"
import BulletList from "../../../components/bullet-list"
import Constrain from "../../../components/constrain"
import Container from "../../../components/container"
import Divider from "../../../components/divider"
import FloatContainer from "../../../components/float-container"
import Heading from "../../../components/heading"
import HyperCardMini from "../../../components/hyper-card-mini"
import Image from "../../../components/image"
import Inline from "../../../components/inline"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Paragraph from "../../../components/paragraph"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import VideoPlayer from "../../../components/video-player"

import logoLiLand from "../../../images/partner-logos/liland-bawue.svg"
import logoLiLandWhite from "../../../images/partner-logos/liland-bawue-white.svg"

const IndexPage = () => {
  const [colorMode] = useColorMode()

  const data = useStaticQuery(graphql`
    query HoelderlinLiebtAusstellung {
      titel: file(relativePath: { eq: "startseite/hoelderlin-liebt.jpg" }) {
        ...mediumImage
      }
      poster: file(
        relativePath: { eq: "videos/poster-ausstellungseroeffnung.jpg" }
      ) {
        ...faktenPosterImage
      }
      podcast: file(relativePath: { eq: "startseite/podcast-inklusiv.png" }) {
        ...smallImage
      }
      leichteSprache: file(
        relativePath: { eq: "startseite/leichte-sprache.png" }
      ) {
        ...smallImage
      }
      ministerium: file(
        relativePath: {
          eq: "partner-logos/ministerium-soziales-integration.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 200, layout: CONSTRAINED)
        }
      }
      ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlin liebt"
        description="Der Dichter Friedrich Hölderlin liebt die Wörter. Die schwierigen ganz besonders. Seine Gedichte sind voll von ungewöhnlichen Wörtern. Das macht sie besonders, aber auch schwer zu verstehen. Manche sagen sogar: Es sind die schwierigsten Gedichte in deutscher Sprache. Die Gedichte von Hölderlin sind das Gegenteil von einfach. Das soll sich mit unserer Sonder-Ausstellung im Museum Hölderlin∙turm nun ändern."
        image={data.ogImage.fixed}
      />

      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Ausstellungen",
              link: "/ausstellungen",
            },
            {
              title: "Hölderlin liebt",
              link: "/ausstellungen/sonderausstellungen/hoelderlin-liebt",
            },
          ]}
        />
        <Stack space={[12, 24]}>
          <Stack>
            <PageTitle>Hölderlin liebt…</PageTitle>
            <Heading as="h2" level={3}>
              Eine Ausstellung in Einfacher Sprache und Gebärdensprache
            </Heading>
            <Heading as="strong" level={4} color="primary">
              9. Mai 2021 bis 14. Februar 2022
            </Heading>
            <Box>
              <FloatContainer width="40%" align="right">
                <VideoPlayer
                  src="hoelderlin-liebt/ausstellungseroeffnung"
                  poster={data.poster}
                />
              </FloatContainer>
              <Paragraph mb={[6, 12]}>
                Der Dichter Friedrich Hölderlin liebt die Wörter.
                <br />
                Die schwierigen ganz besonders. <br />
                Seine Gedichte sind voll von ungewöhnlichen Wörtern. <br />
                Das macht sie besonders, <br />
                aber auch schwer zu verstehen. <br />
                Manche sagen sogar:
                <br />
                Es sind die schwierigsten Gedichte in deutscher Sprache.
                <br />
                Die Gedichte von Hölderlin sind das Gegenteil von einfach.
              </Paragraph>
              <Paragraph mb={[6, 12]}>
                Das soll sich mit der Sonderausstellung im Museum Hölderlinturm
                nun ändern. <br />
                Vom 9. Mai 2021 bis 14. Februar 2022 können Sie <br />
                Friedrich Hölderlin und seine Gedichte <br />
                in Einfacher Sprache und Gebärdensprache kennen lernen.
              </Paragraph>

              <Paragraph mb={[6, 12]}>
                Dort können Sie auf Hölderlins Fersen <br />
                durch den langen Flur im Erdgeschoss gehen. <br />
                Sie können aus einem Bällebad mit Hölderlins Lieblingswörtern{" "}
                <br />
                Ihr eigenes Lieblingswort auswählen. <br />
                Sie können die Pflanzen und Blumen aus seinen Gedichten riechen.{" "}
                <br />
                Und Sie können die Landschaften aus dem Gedicht ›Hälfte des
                Lebens‹ <br />
                in einem Magnet-Bilder-Spiel selbst zusammensetzen.
              </Paragraph>
              <Paragraph mb={[6, 12]}>
                Eine Ausstellung, die Alle dazu einlädt, die Poesie der
                Einfachen Sprache und Gebärdensprache zu entdecken.
              </Paragraph>
            </Box>
          </Stack>

          <Container maxWidth="3xl" center={false}>
            <Stack space={12}>
              <HyperCardMini
                headingElement="h3"
                title="Digitale Ausstellung"
                description="In dieser digitalen Austellung können Sie Friedrich Hölderlin und seine Gedichte in Einfacher Sprache und Gebärdensprache kennen lernen."
                image={data.titel}
                to="/sonderausstellungen/hoelderlin-liebt"
                cta="Jetzt ansehen"
              />
              <HyperCardMini
                headingElement="h3"
                title="Infos zum Museumsbesuch in Leichter Sprache"
                image={data.leichteSprache}
                to="/leichte-sprache"
                cta="Mehr lesen"
              />
              <HyperCardMini
                headingElement="h3"
                title="Podcast zur Ausstellung"
                image={data.podcast}
                to="/neuigkeiten/hoelderlin-inklusiv"
                cta="Jetzt anhören"
              />
              <BookHoelderlinLiebt />
            </Stack>
          </Container>

          <Stack>
            <Heading as="h2" level={2}>
              Über die Entstehung der Ausstellung
            </Heading>
            <Paragraph>
              Für die Ausstellung haben wir mit vielen Menschen
              zusammengearbeitet:
            </Paragraph>

            <Stack>
              <BulletList size={[3, 3, 4, 5]}>
                <Bullet>
                  Mit der Autorin Stephanie Jaeckel und der Illustratorin Jette
                  von Bodecker
                </Bullet>
                <Bullet>
                  Mit den Bewohner*innen und Betreuer*innen der
                  Bruderhaus-Diakonie in Tübingen
                </Bullet>
                <Bullet>
                  MIt einem inklusiven Kunstkurs von der Lebenshilfe Tübingen
                </Bullet>
                <Bullet>
                  Mit Schüler*innen der Tübinger Kirnbachschule und der
                  Nürtinger Johannes-Wagner-Schule Nürtingen für
                  Hörbeeinträchtigte
                </Bullet>
                <Bullet>
                  Mit Krishna-Sara Helmle von Textöffner, dem Büro für Leichte
                  Sprache in Tübingen
                </Bullet>
                <Bullet>
                  Und mit einem Team aus gehörlosen und hörenden
                  Gebärdensprach-Dolmetscherinnen
                </Bullet>
              </BulletList>
              <Paragraph>
                Wir wissen jetzt: <br />
                Hölderlin in Einfacher Sprache und in Gebärdensprache <br />
                – das geht! <br />
                Wenn verschiedene Menschen zusammen kommen, <br />
                kann etwas Neues entstehen. <br />
                Hölderlin kann einfach werden, <br />
                sogar lautlos. <br />
                Hölderlin kann leuchten.
              </Paragraph>
            </Stack>
            <Divider size={4} />
            <Constrain align="left">
              <Stack>
                <Paragraph size={3}>
                  Das Projekt ›Hölderlin in Einfacher Sprache‹ wird unterstützt
                  durch das Ministerium für Soziales und Integration
                  Baden-Württemberg. Die Übersetzungen in Deutsche
                  Gebärdensprache wurden gefördert durch die Arbeitsstelle für
                  literarische Museen, Archive und Gedenkstätten in
                  Baden-Württemberg.
                </Paragraph>
                <Inline alignY="center" space={12}>
                  <Box sx={{ width: 48 }}>
                    <Image
                      image={data.ministerium.childImageSharp.gatsbyImageData}
                      alt="Logo Ministerium für Soziales und Integration Baden-Württemberg"
                    />
                  </Box>
                  <img
                    src={colorMode === "light" ? logoLiLand : logoLiLandWhite}
                    alt="Logo Literaturland Baden-Württemberg"
                    width="82"
                    height="92"
                    sx={{ width: "auto", height: 24 }}
                  />
                </Inline>
              </Stack>
            </Constrain>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default IndexPage
